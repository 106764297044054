define(['componentsCore', 'wixappsCore/core/typesConverter', 'wixappsCore/proxies/mixins/inputProxy'],
    function (componentsCore, /** wixappsCore.typesConverter */typesConverter, inputProxy) {
        'use strict';

        /**
         * @class proxies.TextArea
         * @extends proxies.mixins.inputProxy
         */
        return {
            mixins: [inputProxy],
            renderProxy() {
                const componentType = 'wixapps.integration.components.inputs.TextArea';
                const props = this.getChildCompProps(componentType);
                const data = this.proxyData;
                const isValid = data.valid !== undefined ? data.valid : true;
                props.compData = typesConverter.text(this.state.text, this.getCompProp('maxLength'));

                props.compProp = {
                    placeholder: this.getCompProp('placeholder'),
                    label: this.getCompProp('label'),
                    message: this.getCompProp('message') || !isValid,
                    isPreset: this.getCompProp('isPreset'),
                    onChange: this.updateText,
                    onBlur: this.onBlur
                };

                return componentsCore.compRegistrar.getCompClass(componentType, true)(props);
            },

            getInitialState() {
                const data = this.proxyData;
                const text = data.text !== undefined ? data.text : data;

                return {
                    text
                };
            },

            updateText(event) {
                const text = event.target.value;
                this.setState({
                    text
                });
            },

            onBlur(event, domID) {
                const text = event.target.value;
                event.type = 'inputChanged'; // eslint-disable-line santa/no-side-effects
                //escaping..?
                event.payload = { // eslint-disable-line santa/no-side-effects
                    value: text
                };
                const path = this.proxyData.text !== undefined ? 'text' : 'this';
                this.setData(text, path);
                this.handleViewEvent(event, domID);
            }
        };
    });
